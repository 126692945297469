@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

/* Fonts */
/* @import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"); */
:root {
  font-family: Inter, system-ui, Avenir, Helvetica, Arial, sans-serif;

  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
}
.fade-in {
  opacity: 1;
  animation-name: fadeIn;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 1s;
}
.icon-spin {
  -webkit-animation: icon-spin 1s infinite linear;
  animation: icon-spin 1s infinite linear;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@keyframes icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
.StateCard_statContent__ssgwg {
  padding: 0px !important;
}
.sc-caSCKo.iDIWZr{
  padding: 0 0 0 0;
}
.sc-iRbamj{
  padding: 0 0 0 0;
}
.ant-pro-global-header{
  position: relative;
  z-index: 1;
}
.ant-table-tbody > tr.highlighted > td {
  background: #FF6B00 !important; 
  color: white;
}
:where(.css-dev-only-do-not-override-1g1dvue).ant-pro-layout .ant-layout-header.ant-pro-layout-header-fixed-header {
  height: 60px !important;
}
:where(.css-dev-only-do-not-override-1g1dvue).ant-pro-layout .ant-pro-sider .ant-layout-sider-children{
  margin-top: 10px !important;
}
:where(.css-dev-only-do-not-override-r0ta0u).ant-avatar.ant-avatar-icon{
  background: transparent !important;
  margin-right: 1px;
}
.StateCard_statNumber__ucjTb {
  margin-top: 13px !important;
  font-weight: 700 !important;
}
:where(.css-dev-only-do-not-override-a4melq).ant-card:not(.ant-card-bordered){
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15) !important;

}
:where(.css-dev-only-do-not-override-1g1dvue).ant-pro-card:where(.css-dev-only-do-not-override-1g1dvue).ant-pro-card-size-small .ant-pro-card-body{
  padding: 0px !important;
}
:where(.css-dev-only-do-not-override-a4melq).ant-list .ant-list-item {
  padding-left: 30px !important ;
  font-size: 14px !important;
  font-weight: 400 !important;
}


.isin-cell {
  width: 200px; /* Fixed width for the cell */
  max-width: 200px; /* Ensure the cell doesn't grow beyond this width */
  overflow-x: auto; /* Enable horizontal scrolling */
  overflow-y: auto; /* Enable vertical scrolling */
  white-space: nowrap; /* Prevent text from wrapping */
  padding: 8px; /* Add some padding for better readability */
  box-sizing: border-box; /* Ensure padding is included in the width */
}

/* Optional: Add a scrollbar style */
.isin-cell::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.isin-cell::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.isin-cell::-webkit-scrollbar-thumb:hover {
  background: #555;
}